import React, { Component } from "react"
import Helmet from "react-helmet"
import { withPrefix } from 'gatsby'
import Layout from "../layout"
import config from "../../data/SiteConfig"
import './about.scss'

import SideBar from '../components/SideBar/SideBar'

class AboutPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <div className='video-wrapper' style={{ position: 'fixed', zIndex: '0', width: '100%', height: '100%' }}>
          <video playsInline autoPlay muted loop poster="img/index_web_mobile_back.jpg" id="bgvid">
            <source src={withPrefix('home-video.mp4')} type="video/mp4" />
          </video>
        </div>
        <div className="about-container">
          <Helmet title={`About | ${config.siteTitle}`} />
          <div className="header">
            <SideBar className="about-sidebar" />
          </div>

          <div className="content-wrapper">
            <div className="title purple">About AVSTRACT</div>
            <div className="text-container">
              <div className="section">
                <p>Let’s start honestly, we have one cliche: we love film.</p>
                <p>
                  Now, that’s the first and last cliche you’ll see from us. If you’re interested in tired tropes, overdone
                  plots and twists you could see a mile away, look somewhere else. See, we deal in
                  the delirious. We work with the whimsical. We create the type of captivating films
                  that can’t be experienced from anyone else.
                </p>
                <p>
                  Our company, Avstract, is the result of minds that can’t do anything but write and produce films to make you laugh, cry
                  and curse. So take pity on our poor souls, watch us — you won’t regret it.
                </p>
              </div>
              <div className="section">
                <div className="about-name mint">Nick Pletcher</div>
                <div className="info">
                  <img alt="Nick Pletcher" src={withPrefix('nick.jpg')} />
                  <p>
                    He’s on a mission from God. Well, maybe less Blues Brothers and more Jurassic Park, Nick Pletcher is
                    the resident movie maniac. To put this in perspective, he still buys DVDs and cried when MovieStop
                    closed. But, like all good psychos, he puts his obsession to work at AVSTRACT as a writer, producer,
                    director, actor and editor. Nick brings over a decade of film experience to AVSTRACT along with a good
                    dose of humor, insight and resourcefulness. 
                  </p>
                </div>
                <div className="favorite-avstract"><span className="mint">Favorite AVSTRACT film:</span> Pizza Girl</div>
                <div className="favorite-film"><span className="mint">Favorite film of all time:</span> Lord of the Rings (which one)</div>
              </div>
              <div className="section">
                <div className="about-name yellow">David Akridge</div>
                <div className="info">
                  <img alt="David Akridge" src={withPrefix('david.jpg')} />
                  <p>
                    Perhaps the most creatively diverse, David Akridge has a knack for art in all its forms. As the
                    neighborhood Boy Wonder, David acts, directs, sings, plays guitar, writes and does a mean improv
                    routine. In fact, improv was his introduction to acting which segued into filmmaking. Now, he sees
                    movies as the ultimate artform, an outlet to create entire universes and share his vision with the world.
                  </p>
                </div>
                <div className="favorite-avstract"><span className="yellow">Favorite AVSTRACT film:</span> The Day Comedy Died</div>
                <div className="favorite-film"><span className="yellow">Favorite film of all time:</span> Airplane</div>
              </div>
              <div className="section">
                <div className="about-name orange">Angelo Guevara-Malavé</div>
                <div className="info">
                  <img alt="Angelo Guevara-Malavé" src={withPrefix('angelo.jpg')} />
                  <p>
                    You could say he lives in a fantasy world, and you’d be right. But Angelo Guevara-Malavé, doesn’t just
                    have stars in his eyes, he’s got a fire in his belly. As a writer, director and actor for AVSTRACT,
                    Angelo’s strength lives in the intricate details. His greatest happiness comes from creating something
                    out of nothing, immersing audiences in a world all his own.
                  </p>
                </div>
                <div className="favorite-avstract"><span className="orange">Favorite AVSTRACT film:</span> Johnee Rocket</div>
                <div className="favorite-film"><span className="orange">Favorite film of all time:</span> Ferris Bueller’s Day Off</div>
              </div>
              <div className="section">
                <div className="about-name red">Dan Reinert</div>
                <div className="info">
                  <img alt="Dan Reinert" src={withPrefix('dan.jpg')} />
                  <p>
                    You can take the boy out of New Jersey, but you can’t take the – well, nevermind. Dan Reinert may be a
                    muscled, hothead guido, but don’t let that fool you. He brings Avstract’s wildest dreams to life with the
                    finesse of an 16th-century Italian sculptor. As an illustrator, Dan’s ability to create life on paper
                    almost rivals God (almost). And his skills in writing, editing, directing and acting aren’t too shabby
                    either. As a coveted team member, Dan’s humor and authenticity give Avstract that unique feel audiences love.
                  </p>
                </div>
                <div className="favorite-avstract"><span className="red">Favorite AVSTRACT film:</span> Planet Homosapien</div>
                <div className="favorite-film"><span className="red">Favorite film of all time:</span> Good Fellas</div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default AboutPage
